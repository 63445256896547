/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    code: "code",
    pre: "pre",
    a: "a"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      children: "Font Families"
    }), "\n", _jsxs(_components.p, {
      children: ["If you'd like to customize your values for font families, use the ", _jsx(_components.code, {
        children: "theme.fonts"
      }), " section of your theme."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-diffjs",
        children: "  // theme.js\n  export const theme = {\n    fonts: {\n+     display: 'Oswald, ...',\n+     body: 'Open Sans, ...',\n    },\n  }\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Fonts have to be specified exactly like you specify them in ", _jsxs(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/CSS/font-family",
        children: ["CSS property ", _jsx(_components.code, {
          children: "font-family"
        })]
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["Learn more about customizing the default theme in the ", _jsx(_components.a, {
        href: "@TODO-link",
        children: "theme customization documentation"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["If you don't want to customize it, a set of ", _jsx(_components.code, {
        children: "fonts"
      }), " is already defined in default theme:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const defaultTheme = {\n  // ...\n  fonts: {\n    mono: `ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, \"Liberation Mono\", \"Courier New\", monospace`,\n    serif: `ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif`,\n    sans: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"`,\n  },\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
